import {
  Box,
  Button,
  Input,
  Select,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/core"
import React, { useState } from "react"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { SEO } from "../components/seo"

const SecondPage = () => {
  const toast = useToast()
  const [message, setMessage] = useState()
  const [name, setName] = useState()
  const [mail, setMail] = useState()
  const [subject, setSubject] = useState()

  const onNameChange = (e: any) => {
    setName(e.target.value)
  }
  const onSubjectChange = (e: any) => {
    setSubject(e.target.value)
  }
  const onMailChange = (e: any) => {
    setMail(e.target.value)
  }
  const onMessageChange = (e: any) => {
    setMessage(e.target.value)
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    fetch("https://formspree.io/mqkyyzqw", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify({
        name,
        subject,
        mail,
        message,
      }),
    })
    toast({
      title: "Az e-mail elküldtük, hamarosan felvesszük veled a kapcsolatot.",
      status: "success",
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <Layout>
      <SEO title="KAPCSOLAT" />
      <Title text="KAPCSOLAT" />

      <Box my={4}>
        <form onSubmit={handleSubmit}>
          <Input
            placeholder="Név"
            size="lg"
            my={3}
            onChange={onNameChange}
            isRequired
          />
          <Select
            placeholder="Tárgy"
            size="lg"
            my={3}
            onChange={onSubjectChange}
            isRequired
          >
            <option value="registration">Önkéntes jelentkezés</option>
            <option value="changes">Változtatások bejelentése</option>
            <option value="decline">Lemondás</option>
            <option value="other">Egyéb</option>
          </Select>
          <Input
            placeholder="E-mail"
            size="lg"
            my={3}
            type="email"
            onChange={onMailChange}
            isRequired
          />
          <Textarea placeholder="Üzenet" my={3} onChange={onMessageChange} />

          <Button
            maxW="256px"
            variantColor="green"
            size="lg"
            my={3}
            type="submit"
          >
            Küldés
          </Button>
        </form>
      </Box>
    </Layout>
  )
}
export default SecondPage
